export default [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'feature',
    label: 'Features',
  },
  {
    path: 'about',
    label: 'About',
  },
  {
    path: 'pricing',
    label: 'Pricing',
  },
];
